// src/Card.js
import React from 'react';

export const ImageCard = ({ day, date, title, description, image }) => {
    return (
        <div className="h-full relative block w-full rounded overflow-hidden shadow-lg
        transform transition duration-300 border-4 hover:border-4 hover:border-orange-200 hover:underline text-white">
            <div className="absolute inset-0 bg-black opacity-50 hover:opacity-25 transition duration-300"></div>
            <img className="absolute inset-0 w-full h-full object-cover" src={ image } alt={ title }/>
            <div className="absolute inset-0 bg-gray-800 bg-opacity-50"></div>
            <div className="relative z-10 flex items-center justify-center h-full">
                <div className="text-center text-white">
                    <h1 className="text-3xl font-bold mb-2 hover:underline magiesta capitalize">{ day }</h1>
                    <h2 className="text-2xl font-bold mb-2 hover:underline magiesta">{ date }</h2>
                    <h2 className="text-2xl font-bold mb-2 hover:underline magiesta">{ title }</h2>
                    <p className="text-base">{ description }</p>
                </div>
            </div>
        </div>
    );
};

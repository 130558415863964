import React from 'react';
import { Location } from "../components/Home/Location";
import { InviteCard } from "../components/Home/InviteCard";
import { TableSetup } from "../components/Home/TableSetup";
import { Menu } from "../components/Home/Menu";
import { HungarianHabit } from "../components/Home/HungarianHabit";
import { Gifts } from "../components/Home/Gifts";
import { FotoLineUp } from "../components/Home/FotoLineUp";
import { Questions } from "../components/Home/Questions";
import { Invitation } from "../components/Home/Invitation";
import { useSearchParams } from "react-router-dom";

export const Home = () => {
    const [searchParams] = useSearchParams();
    const filter = searchParams.get('audience') === "family";

    return <>
        <div className={ "container mx-auto" }>
            <div className="px-4">
                <InviteCard/>
            </div>
        </div>
        <div className={ "bg-secondary" }>
            <div className={ "container mx-auto " }>
                <div className="px-4">
                    <Invitation/>
                </div>
            </div>
        </div>
        <Location filter={ filter }/>
        <div className={ "container mx-auto" }>
            <div className="px-4">
                <TableSetup/>
            </div>
        </div>

        <div className={ "bg-secondary" }>
            <div className={ "container mx-auto " }>
                <div className="px-4">
                    <Menu/>
                </div>
            </div>
        </div>

        <div className={ "container mx-auto" }>
            <div className="px-4">
                <FotoLineUp filter={ filter }/>
            </div>
        </div>
        <div className={ "bg-secondary h-32"}>
        </div>
        <div className={ "container mx-auto" }>
            <div className="px-4">
                <HungarianHabit/>
            </div>
        </div>

        <div className={ "bg-secondary" }>
            <div className={ "container mx-auto " }>
                <div className="px-4">
                    <Gifts/>
                </div>
            </div>
        </div>
        <div className={ "container mx-auto" }>
            <div className="px-4">
                <Questions/>
            </div>
        </div>
    </>
        ;
};

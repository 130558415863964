import { Card } from "../Card";
import React from "react";
import { CardTitle } from "../CardTitle";
import { useTranslation } from "react-i18next";
import tableSetupOverview from "../../assets/images/table-setup-overview.jpeg";
import tableSetup1 from "../../assets/images/table-setup-1.jpeg";
import tableSetup2 from "../../assets/images/table-setup-2.jpeg";
import tableSetup3 from "../../assets/images/table-setup-3.jpeg";
import tableSetup4 from "../../assets/images/table-setup-4.jpeg";
import tableSetup5 from "../../assets/images/table-setup-5.jpeg";
import tableSetup6 from "../../assets/images/table-setup-6.jpeg";
import tableSetup7 from "../../assets/images/table-setup-7.jpeg";
import tableSetup8 from "../../assets/images/table-setup-8.jpeg";
import tableSetup9 from "../../assets/images/table-setup-9.jpeg";
import tableSetup10 from "../../assets/images/table-setup-10.jpeg";
import tableSetup11 from "../../assets/images/table-setup-11.jpeg";

export const TableSetup = props => {
    const { t } = useTranslation();

    return (
        <Card id="table-setup" className={ "min-h-[70vh] my-4" }>
            <CardTitle titleClassnames={ "text-white text-3xl" }>
                { t("table-setup") }
            </CardTitle>
            <img src={ tableSetupOverview } alt="tableSetup 2" className="w-full h-auto"/>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                <div className="md:col-span-1 relative ">
                    <img src={ tableSetup1 } alt="tableSetup 1" className="w-full h-auto sticky top-20 z-40"/>
                </div>

                <div className="md:col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
                    <img src={ tableSetup2 } alt="tableSetup 3" className="w-full h-auto"/>
                    <img src={ tableSetup3 } alt="tableSetup 4" className="w-full h-auto"/>
                    <img src={ tableSetup4 } alt="tableSetup 5" className="w-full h-auto"/>
                    <img src={ tableSetup5 } alt="tableSetup 6" className="w-full h-auto"/>
                    <img src={ tableSetup6 } alt="tableSetup 7" className="w-full h-auto"/>
                    <img src={ tableSetup7 } alt="tableSetup 8" className="w-full h-auto"/>
                    <img src={ tableSetup8 } alt="tableSetup 9" className="w-full h-auto"/>
                    <img src={ tableSetup9 } alt="tableSetup 10" className="w-full h-auto"/>
                    <img src={ tableSetup10 } alt="tableSetup 11" className="w-full h-auto"/>
                    <img src={ tableSetup11 } alt="tableSetup 11" className="w-full h-auto"/>
                </div>
            </div>
        </Card>
    )
}
import { LocationChooser } from "./Location/LocationChooser";
import { LocationCard } from "./Location/LocationCard";
import { useTranslation } from "react-i18next";
import coctailHu from "../../assets/images/coctail-hu.jpeg";
import coctailEn from "../../assets/images/coctail-en.jpeg";
import ceremoni from "../../assets/images/ceremoni.jpeg";
import programHu from "../../assets/images/program-hu.jpeg";
import programEn from "../../assets/images/program-en.jpeg";
import monkeyMap from "../../assets/images/monkey-map.jpg";
import dressCodeCoctailEn from "../../assets/images/dress-code-coctail-en.jpeg";
import dressCodeCoctailHu from "../../assets/images/dress-code-coctail-hu.jpeg";
import brunchHU from "../../assets/images/brunch-hu.jpeg";
import brunchEn from "../../assets/images/brunch-en.jpeg";
import brunchMain from "../../assets/images/brunch-main.jpeg";
import brunchDressCodeHu from "../../assets/images/brunch-dress-code-hu.jpeg";
import brunchDressCodeEn from "../../assets/images/brunch-dress-code-en.jpeg";
import coctailParty from "../../assets/images/coctail-party.jpeg";
import { ImageCard } from "../ImageCard";
import React from "react";
import ceremoniPlace from "../../assets/images/ceromoni-place.jpeg";
import brunch from "../../assets/images/brunch2.jpeg";
import bigDayDressCodeHu from "../../assets/images/big-day-dress-code-hu.jpeg";
import bigDayDressCodeEn from "../../assets/images/big-day-dress-code-en.jpeg";


export const Location = ({filter}) => {
    const { t, i18n } = useTranslation();

    const isHU = i18n.language === "hu";
    const program = isHU ? programHu : programEn
    const dressCodeCoctailImg = isHU ? dressCodeCoctailHu : dressCodeCoctailEn;
    const brunchLangImg = isHU ? brunchHU : brunchEn;
    const brunchDressCodeImg = isHU ? brunchDressCodeHu : brunchDressCodeEn;
    const bigDayDressCodeImg = isHU ? bigDayDressCodeHu : bigDayDressCodeEn;

    return (
        <section id="location" className={ "relative" }>
            <LocationChooser id={ "location" } filter={filter}/>
            <div>
                <div className={ "block md:hidden h-64 p-4" }>
                    <ImageCard
                        title={ 'Monkey Bistro' }
                        description={ "Budapest, Ybl Miklós tér 4, 1013 Hungary" }
                        image={ coctailParty }
                        date={t('location-1-date')}
                        day={t('location-1-title')}
                    />
                </div>
                <div className={ "bg-secondary overflow-hidden" }>
                    <div className={ "container mx-auto py-4" }>
                        <div className={ "px-4" }>
                            <LocationCard
                                title={ t("location-1-title") }
                                titleClassNames={ "uppercase tracking-widest text-3xl underline" }
                                id={ "location-1" }
                                mapsLocation={ {
                                    lat: 47.495609981891235,
                                    lng: 19.04170934969449,
                                } }
                                cardClassName={ "bg-secondary shadow-none" }
                                secondPart={ <div
                                    className={ "flex flex-col md:flex-row justify-center items-center md:items-end h-100% relative mb-3 md:mb-0" }>
                                    <div className={ "md:absolute md:top-[-50px] md:left-0 md:z-10 md:-rotate-24" }>
                                        <img src={ dressCodeCoctailImg } alt=""
                                             className={ "h-[200px]" }
                                        />
                                    </div>

                                    <img src={ monkeyMap } alt="Monkey map"
                                         className={ "rounded-full w-[60%] grayscale-50" }/>
                                </div> }
                            >
                                { i18n.language === "hu" && <img src={ coctailHu } alt=""/> }
                                { i18n.language === "en" && <img src={ coctailEn } alt=""/> }
                            </LocationCard>
                        </div>
                    </div>
                </div>
                <div className={ "block md:hidden h-64 p-4" }>
                    <ImageCard
                        title={ 'Villa Fontaine' }
                        description={ "Budapest, Lóránt út 15, 1125 Hungary" }
                        image={ ceremoniPlace }
                        date={t('location-2-date')}
                        day={t('location-2-title')}
                    />
                </div>
                <div className={ "container mx-auto" }>
                    <div className={ "px-4" }>
                        <LocationCard
                            title={ t("location-2-title") }
                            id={ "location-2" }
                            mapsLocation={ {
                                lat: 47.50216907933962,
                                lng: 18.99790402089879,
                            } }
                            cardClassName={ "bg-primary shadow-none text-white" }
                            titleClassNames={ "uppercase tracking-widest text-3xl underline !border-white" }
                            secondPart={ <div
                                className={ "flex justify-center items-center mt-10 w-full mb-3 md:mb-0" }>
                                <img src={ program } alt="Program" className={ "max-h-[60vh]  max-w-[80%] rounded max-w-full" }/>
                            </div> }
                            mapContent={ <div className={ "text-center text-white pt-5" }>
                                { i18n.language === "hu" &&
                                    <div>
                                        <h2 className={ "my-2 uppercase " }>Megközelítés</h2>
                                        Autóval a parkolás az utcán megoldható, ami szombaton ingyenes. A villa könnyen
                                        megközelíthető tömegközlekedéssel is, illetve taxival/Bolttal.
                                    </div> }
                                { i18n.language === "en" && <div>
                                    <h2 className={ "my-2 uppercase " }>Getting there</h2>
                                    Parking is only possible on the streets, but it is free on Saturdays.
                                    The villa is easily accessible by public transportation, as well as by taxi or Bolt.
                                </div> }
                            </div> }
                        >
                            <div className={ "flex flex-col md:flex-row justify-center items-center relative" }>
                                <img src={ ceremoni } alt="Ceremoni" className={ "max-h-[60vh]" }/>
                                <div
                                    className={ "md:absolute md:bottom-[-150px] md:left-[-60px] md:z-10 md:rotate-15" }>
                                    <img src={ bigDayDressCodeImg } alt="" className={ "h-[200px]" }/>
                                </div>
                            </div>
                        </LocationCard>

                    </div>
                </div>
                { filter && <div className={ "bg-secondary" }>
                    <div className={ "block md:hidden h-64 p-4" }>
                        <ImageCard
                            title={ 'Citadella Guesthouse' }
                            description={ "Budapest, Rezeda u. 9, 1118 Hungary" }
                            image={ brunch }
                            date={ t('location-3-date') }
                            day={ t('location-3-title') }
                        />
                    </div>
                    <div className={ "container mx-auto py-4" }>
                        <div className={ "px-4" }>
                        <LocationCard
                                title={ t("location-3-title") }
                                titleClassNames={ "uppercase tracking-widest text-3xl underline" }
                                id={ "location-3" }
                                mapsLocation={ {
                                    lat: 47.48422893035784,
                                    lng: 19.046686941773828,
                                } }
                                cardClassName={ "bg-secondary shadow-none" }
                                secondPart={ <div
                                    className={ "flex flex-col md:flex-row justify-center items-center md:items-end h-100% relative mb-3 md:mb-0" }>
                                    <div className={ "md:absolute md:top-[15px] md:left-[-60px] md:z-10 md:rotate-15" }>
                                        <img src={ brunchDressCodeImg } alt=""
                                             className={ "h-[200px]" }
                                        />
                                    </div>

                                    <img src={ brunchMain } alt="Monkey map"
                                         className={ "rounded-full w-[60%] grayscale-50" }/>
                                </div> }
                            >
                                <div className={ "flex justify-center items-center" }>
                                    <img src={ brunchLangImg } alt="Ceremoni" className={ "" }/>
                                </div>
                            </LocationCard>
                        </div>
                    </div>
                </div> }

            </div>


        </section>
    )
}

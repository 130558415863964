import { Card } from "../Card";
import React from "react";
import { useTranslation } from "react-i18next";
import giftingHu from "../../assets/images/gifting-hu.jpeg";
import giftingEn from "../../assets/images/gifting-en.jpeg";

export const Gifts = () => {
    const { t, i18n } = useTranslation();

    const giftImage = i18n.language === "hu" ? giftingHu : giftingEn;
    return (
        <Card id="gifts" className={ "my-4 py-10" }>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 h-full">
                <div className="md:col-span-2 grid grid-cols-1 gap-4 flex items-center justify-center">
                    <img src={ giftImage } alt="tableSetup 1" className="w-full h-auto"/>
                </div>
                <div className="md:col-span-1 flex items-center justify-center">
                    <div>
                        <p className={"text-justify md:mt-[120px] text-lg"}>
                            { t("gifting-text") }
                        </p>
                        <p className={'mt-16 text-center'}>
                            { t("gifting-thanks") }
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    );
}
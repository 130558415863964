// src/components/Navbar.js
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';

export const Navbar = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const filter = searchParams.get('audience');

    const changeLanguage = (lng) => {
        const pathParts = location.pathname.split('/');
        if (pathParts[1] === i18n.language) {
            pathParts[1] = lng; // Replace the language part
        }
        let newPath = pathParts.join('/');
        if(filter && filter=== "family") {
            newPath += "?audience=family";
        }
        i18n.changeLanguage(lng);
        navigate(newPath);
        setIsOpen(false); // Close the mobile menu after changing the language
    };

    const menuPoints = [
        {
            title: "invitation",
            location: "invitation",
        },
        {
            title: "locations",
            location: "location",
        },
        {
            title: "table-setup",
            location: "table-setup",
        },
        {
            title: "menu",
            location: "menu",
        },
        {
            title: "foto-line-up",
            location: "foto-line-up",
        },
        {
            title: "hungarian-habit",
            location: "hungarian-habit",
        },
        {
            title: "gifts",
            location: "gifts",
        },
        {
            title: "questions",
            location: "questions",
        },
    ]

    return (
        <nav className="bg-secondary sticky top-0 bg-white shadow-md z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-10">
                    <div className="flex items-center">
                        <Link
                            to="invite-card"
                            smooth={ true } duration={ 500 }
                            className="flex-shrink-0  py-2 px-4 rounded cursor-pointer"
                            offset={-80}
                        >
                            <h1 className="text-primary text-2xl apostrophic">
                                Dagi Wedding
                            </h1>
                        </Link>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {
                                    menuPoints.map((item, i) => (
                                        <Link to={ item.location }
                                              key={ item.location }
                                              smooth={ true } duration={ 500 }
                                              offset={-50}
                                              className="text-primary hover:underline px-3 py-2 rounded-md text-sm font-medium cursor-pointer font-didot lowercase">
                                            { t(item.title) }
                                        </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button onClick={ () => changeLanguage('en') }
                                className="text-white px-3 py-2 rounded-md text-sm font-medium">
                            <img src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                                 alt="English" className="h-[20px] w-[20px] object-cover inline filter grayscale hover:filter-none transition-all duration-300"/>
                        </button>
                        <button onClick={ () => changeLanguage('hu') }
                                className="text-white px-3 py-2 rounded-md text-sm font-medium">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg"
                                 alt="Hungarian" className="h-[20px] w-[20px] object-cover inline filter grayscale hover:filter-none transition-all duration-300"/>
                        </button>
                        <div className="-mr-2 flex md:hidden">
                            <button onClick={ () => setIsOpen(!isOpen) }
                                    className="bg-primary inline-flex items-center justify-center p-1 rounded-md text-gray-200 hover:text-white">
                                <span className="sr-only">Open main menu</span>
                                { isOpen ? (
                                    <FontAwesomeIcon icon={ faTimes } className="block h-6 w-6"/>
                                ) : (
                                    <FontAwesomeIcon icon={ faBars } className="block h-6 w-6"/>
                                ) }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={ `${ isOpen ? 'block' : 'hidden' } md:hidden` } id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {
                        menuPoints.map((item, i) => (
                            <Link to={ item.location }
                                  key={ item.location }
                                  smooth={ true } duration={ 500 }
                                  offset={-50}
                                  className="text-primary hover:underline block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
                                  onClick={ () => setIsOpen(false) }>
                                { t(item.title) }
                            </Link>
                        ))
                    }
                </div>
            </div>
        </nav>
    );
};

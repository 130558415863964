import { Card } from "../Card";
import { CardTitle } from "../CardTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import hungarianHabit from "../../assets/images/hungarian-habit.jpeg"

export const HungarianHabit = () => {
    const { t, i18n } = useTranslation();

    return (
        <Card id="hungarian-habit" className={ "min-h-[50vh] my-4" }>
            <CardTitle titleClassnames={ "text-white uppercase tracking-widest text-3xl underline" }>
                { t("hungarian-habit") }
            </CardTitle>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-white">
                <div className={ "px-10 flex flex-col justify-center" }>
                    <p className={ "mb-10 text-lg" }>
                        { t('hungarian-habit-1-title') }
                    </p>
                    <p className={ "text-lg" }>
                        { i18n.language === "hu" && t('hungarian-habit-1-text') }
                        { i18n.language === "en" && <div>
                            A Hungarian woman wears red after midnight on her wedding, signaling her transformation from
                            bride to wife. We celebrate this momentous occasion with an opportunity to purchase a dance
                            with the newlyweds.
                            <br/>
                            <br/>
                            Your cue:
                            when you see the bride in red, join the already forming queue (this would feel very natural
                            for our guests living in Britain)
                            with your gift in hand.
                        </div> }
                    </p>
                </div>
                <div>
                    <img src={ hungarianHabit } alt="" className={ "w-full" }/>
                </div>
                <div className={ "px-10 flex flex-col justify-center mb-16 md:mb-0" }>
                    <p className={ "mb-10 text-lg" }>
                        { t('hungarian-habit-2-title') }
                    </p>
                    <p className={ "text-lg" }>
                        { i18n.language === "hu" && t('hungarian-habit-2-text') }
                        { i18n.language === "en" && <div>
                            A Hungarian couple’s first task as newlyweds is to sweep and gather the coins that the
                            guests very kindly sprinkle onto the floor for them. This is a test of how well they can
                            cooperate in their married life.
                            <br/>
                            <br/>
                            <b>Please prepare with some coins for the occasion beforehand.</b>
                            <br/>
                            <br/>
                            Your cue:
                            when you see the bride grabbing a sweep (she is not planning to fly away) get your coins
                            ready and throw.
                        </div> }
                    </p>
                </div>

            </div>
        </Card>
    )
}

import { Card } from "../../Card";
import GoogleMap from "../../GoogleMap";
import { CardTitle } from "../../CardTitle";
import React from "react";

export const LocationCard = ({
                                 id,
                                 title,
                                 location,
                                 children,
                                 mapsLocation,
                                 cardClassName,
                                 titleClassNames,
                                 mapSize,
                                 secondPart,
                                mapContent
                             }) => {
    return (
        <Card
            id={ id }
            className={ `mx-4 my-8 ${ cardClassName }` }
        >
            <div className={ "flex flex-col md:flex-row " }>
                <div className={ "md:w-1/3 " }>
                    <CardTitle titleClassnames={ titleClassNames }>
                        { title }
                    </CardTitle>

                    <div className={ "" }>
                        { children }
                    </div>
                </div>
                <div className={ "md:w-1/3 flex" }>
                    { secondPart }
                </div>
                <div className={ "md:w-1/3 flex flex-col items-center justify-center" }>
                    <GoogleMap
                        center={ mapsLocation }
                        mapSize={ mapSize }
                    />
                    {mapContent}
                </div>
            </div>

        </Card>
    )
}
import { Card } from "../Card";
import React from "react";
import { CardTitle } from "../CardTitle";
import { useTranslation } from "react-i18next";
import menuImage from "../../assets/images/menu.jpeg"

export const Menu = () => {
    const { t, i18n } = useTranslation();

    return (
        <Card id="menu" className={ "min-h-[40vh] my-4 flex flex-col " }>
            <CardTitle titleClassnames={ "flex text-3xl" }>
                { t("menu") }
            </CardTitle>
            <div className={ "flex w-100" }>
                {
                    i18n.language === "hu" && <div className={ "flex flex-col md:flex-row my-10" }>
                        <div className={ "md:w-1/3 flex flex-col px-10 text-center" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Fogadó falatkák
                            </div>
                            <br/>
                            Sajtos, tepertős, magos házi pogácsák,
                            <br/>
                            <br/>
                            Káposztás, sonkás, füstöltsajtos hasé,
                            <br/>
                            <br/>
                            Sós masni, pizzás masni,
                            <br/>
                            <br/>
                            Füstöltsajtos mini croissant,
                            <br/>
                            <br/>
                            Genovai pestóval pácolt caprese nyárs, fekete olivával,
                            <br/>
                            <br/>
                            Friss bazsalikomos erdei gombakrém, mangalica szalonna chipsszel, mini bouché-ba töltve,
                            <br/>
                            <br/>
                            Mediterrán paprikakrémmel ízesített körözött, csabai kolbász karikákkal, bio hagyma csírákkal,
                            <br/>
                            <br/>
                            Füstölt padlizsánkrémmel töltött kosárka
                        </div>
                        <div className={ "md:w-1/3 px-10 text-center mt-10 md:mt-0" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Leves
                            </div>
                            <br/>
                            Forró újházi tyúkhúsleves/gazdagon tyúkhússal, lúdgége tésztával, zöldség julienne-nel,
                            borsóval/zöld erős paprika, erős Pista
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Sültestál
                            </div>
                            <br/>
                            Mogyoró olajban pácolt szűzérmék, olaszos fűszervajjal, fokhagymás-angolos parajjal
                            <br/>
                            <br/>
                            Aranysárgára sült pulykamell sajttal, feketeerdei füstölt sonkával töltve, rántva
                            <br/>
                            <br/>
                            Pirosra, ropogósra sütött, petrezselymes gombával töltött csirkecombok, szarvasgombás olajjal
                            locsolgatva
                            <br/>
                            <br/>
                            Házi szalonnával sült mangalica karaj, színes fűszerkéregben, snidlinges kecskesajttal dúsított
                            krémmártással
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Köretek
                            </div>
                            <br/>
                            Fűszeres steakburgonya
                            <br/>
                            Burgonyakrém parmezánnal
                            <br/>
                            Rizi-bizi
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Sali
                            </div>
                            <br/>
                            Szivárványos paradicsomsaláta, balzsamos rukkolával
                            <br/>
                            Házi vegyes savanyúság (mini dinnye, töltött uborka, savanyú szilva, almapaprika)
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Desszert
                            </div>
                            <br/>
                            Mousse válogatás kehelyben, Málnás túró, mangós- kókuszos, belga csoki pirított mandulával
                            Málnás-áfonyás, ibolyakrémes tart
                        </div>
                        <div className={ "md:w-1/3 px-10 text-center mt-10 md:mt-0" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Torta
                            </div>
                            <br/>
                            Ízek fentről lefelé:
                            <br/>
                            <br/>
                            túrós málnás,
                            <br/>
                            dobos,
                            <br/>
                            nagyon csokis,
                            <br/>
                            <br/>
                            vegán torta,
                            <br/>
                            cukormentes torta
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Éjféli vacsora
                            </div>
                            <br/>
                            Mini töltött káposzta, tejföl, kenyér
                            Hortobágyi palacsinta
                            <img src={ menuImage } alt="Menu" className={ "" }/>
                        </div>
                    </div>
                }

                {
                    i18n.language === "en" && <div className={ "flex flex-col md:flex-row my-10" }>
                        <div className={ "md:w-1/3 flex flex-col px-10 text-center" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Hors d'oeuvres
                            </div>
                            <br/>
                            Cheese, pork crackling, and seeded homemade savoury scones,
                            <br/>
                            <br/>
                            Cabbage, ham, and smoked cheese pastry,
                            <br/>
                            <br/>
                            Salted bowtie, pizza bowtie,
                            <br/>
                            <br/>
                            Smoked cheese mini croissant,
                            <br/>
                            <br/>
                            Caprese skewer marinated with Genoese pesto and with black olives,
                            <br/>
                            <br/>
                            Mini bouché filled with Fresh basil and wild mushroom cream, with Mangalica bacon chips,
                            <br/>
                            <br/>
                            Mediterranean pepper curd cheese spread, with Csabai sausage slices, and organic onion sprouts,
                            <br/>
                            <br/>
                            Pastry basket filled with smoked eggplant cream
                        </div>
                        <div className={ "md:w-1/3 px-10 text-center mt-10 md:mt-0" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Soup
                            </div>
                            <br/>
                            Hot Újházi chicken soup, with lúdgége pasta, julienne vegetables, peas, green hot pepper,
                            Erős Pista (spicy hot sauce)
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Roast Platter
                            </div>
                            <br/>
                            Tenderloin medallions marinated in peanut oil, with Italian herb butter and spinach in garlic-butter,
                            <br/>
                            <br/>
                            Turkey Cordon Bleu,
                            <br/>
                            <br/>
                            Crispy, red-roasted chicken thighs stuffed with parsley mushrooms, drizzled with truffle oil,
                            <br/>
                            <br/>
                            Mangalica pork loin roasted with homemade bacon, in a colourful spice crust, served with chive-infused goat cheese sauce
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Side Dishes
                            </div>
                            <br/>
                            Herb steak fries
                            <br/>
                            Mashed potatoes with Parmesan
                            <br/>
                            Rizi-bizi (Rice with peas)
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Salads
                            </div>
                            <br/>
                            Rainbow tomato salad with balsamic rockets
                            <br/>
                            Homemade Hungarian pickle selection
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Dessert
                            </div>
                            <br/>
                            Mousse assortment in a cup: Raspberry curd, mango-coconut, Belgian chocolate with toasted almonds,
                            Raspberry-blueberry and lilac-violet cream tart
                        </div>
                        <div className={ "md:w-1/3 px-10 text-center mt-10 md:mt-0" }>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Cake
                            </div>
                            <br/>
                            Flavors from top layer down:
                            <br/>
                            <br/>
                            Raspberry curd,
                            <br/>
                            Dobos (Crunchy-caramel),
                            <br/>
                            Very chocolatey chocolate,
                            <br/>
                            <br/>
                            Vegan cake,
                            <br/>
                            Zero-sugar cake
                            <br/>
                            <br/>
                            <div className={ "text-center uppercase tracking-widest" }>
                                Midnight Dinner
                            </div>
                            <br/>
                            Mini stuffed cabbage, sour cream, Hungarian fluffy bread
                            Hortobágyi pancake
                            <img src={ menuImage } alt="Menu" className={ "" }/>
                        </div>
                    </div>
                }
            </div>
        </Card>
    )
}

import { Card } from "../Card";
import { CardTitle } from "../CardTitle";
import React from "react";
import { useTranslation } from "react-i18next";

export const FotoLineUp = ({filter}) => {
    const { t } = useTranslation();
    return (
        <Card id="foto-line-up" className={ "min-h-[50vh] my-6 flex flex-col" }>
            <CardTitle titleClassnames={"text-white text-3xl !border-white"}>
                { t("foto-line-up") }
            </CardTitle>
            <div className={ "flex flex-col w-100 justify-center items-center text-white text-center" }>
                <div className={ "my-2" }>
                    {t("foto-line-up-intro-1")}
                    <br/>
                    {t("foto-line-up-intro-2")}
                    <br/>
                    <br/>
                </div>
                { filter && <div className={"flex flex-col w-100 justify-center items-center text-center"}>
                    <br/>
                    <h1 className={ "text-3xl" }>15:30 - 16:30</h1>
                    <h2 className={ "text-2xl" }>Vőlegény</h2>
                    <br/>
                    Évi, Ernő <br/>
                    Móni, Balázs, Márk, Szabi <br/>
                    Szunó <br/>
                    <br/>
                    <h1 className={ "text-3xl" }>16:30 - 17:30</h1>
                    <h2 className={ "text-2xl" }>Menyasszony</h2>
                    <br/>
                    Hangi, Patti, Emma, Réka Kitti <br/>
                    Gaurangi <br/>
                    <br/>
                    Icus nagyi, Mami, Bernadett, Fati, Dia <br/>
                    <br/>
                    Kati nagyi, Zoli<br/>
                    <br/>
                </div> }
                <h1 className={ "text-3xl" }>19:00</h1>
                <h2 className={ "text-2xl" }>{ t("foto-line-up-group-text-1") }</h2>
                <br/>
                <h1 className={ "text-3xl" }>19:00 - 19:30</h1>
                <h2 className={ "text-2xl" }>{ t("foto-line-up-group-text-2") }</h2>
                <br/>
                <u>{t("photo-line-up-newlyweds")} + Klausz klán</u>
                Apu, Mariannka, Emma, Réka Kitti, Kati, Nagyi, Leon, Timi, Menta, Tamás <br/>
                <br/>
                <u>{t("photo-line-up-newlyweds")} + groom squad + koszorúslányok</u>
                Márk, Szabi, Szun, Patti, Hangi, Emma, Réka Kitti <br/>
                <br/>
                <h1 className={ "text-3xl" }>19:30 - 20:00</h1>
                <h2 className={ "text-2xl" }>{ t("foto-line-up-group-text-3") }</h2>
                <br/>
                <u>Pethő klán</u>
                Évi, Ernő, Móni, Viktor, Áki, Nóri, Esztike, Bazsi, Netti, Florina, <br/>
                Szabi, Moncsi, Simi, Márk, Márti, Levi <br/>
                <br/>
                <u>Pethő törzs</u>
                Évi, Ernő, Móni, Viktor, Áki, Nóri, Esztike, Bazsi, Netti, Florina, <br/>
                Szabi, Moncsi, Simi, Márk, Márti, Levi, <br/>
                Zsuzsika, Joci, Kassai Jóska, Kassai Éva <br/>
                <br/>
                <u>Öröm szülők</u>
                Évi, Ernő, Carol, Zoli <br/>
                <br/>
                <u>Pintér klán</u>
                Mami, Icus Nagyi, Bernadett, Zsolt, Dia, Fati, Máté, Hangi, Szunó <br/>
                <br/>
                <u>Nagyik</u>
                Icus nagyi, Kati nagyi <br/>
                <br/>
                <u>Kornélék</u>
                Kornél, Beatrix, Léna<br/>
                <br/>
                <u>Csengiék</u>
                Csengi, Gábor, Mariann, Gyuszi<br/>
                <br/>
                <u>A csapat</u>
                Ildi, Tomi, Dani, Hédi, Patti, Rafał, Ágica, Balázs, Carol, Hangi <br/>
                <br/>
                <u>Atádi lányok</u>
                Patti, Eszti, Beus, Timi, Rafał, Attila, Marci, Balázs <br/>
                <br/>
                <u>Brigád</u>
                Ati, Klau, Brinyó, Olivia, Dávidka, Evi, Herczeg, Lola, <br/>
                Lilla, Máté, Mogyi, Timi, Mufi, Luca, Pusztai, Sebi, Janka, <br/>
                Szilveszter, Szunó, Hangi, Tanczi, Dóri <br/>
                <br/>
                <u>Tami, Dani</u>
                <br/>
                <u>International</u>
                Virgi, Mark, Makayla, Edith, Gaurangi, Bartek, David <br/>
                <br/>
                <u>Mary, Zoli</u>
                <br/>
                <u>Maya+</u>
                Lóri, Andris, Hamar, Bogi, Veréb Szabi <br/>
                <br/>
                <u>Bartalos Bazsi, Dóri</u>
            </div>
        </Card>
    )
}

import { useTranslation } from "react-i18next";
import invitationEn from "../../assets/images/invitation-en.jpeg";
import invitationHU from "../../assets/images/invitation-hu.jpeg";

export const Invitation = () => {
    const { i18n } = useTranslation();

    const imageClasses = "w-full md:w-auto md:object-cover md:h-[89vh]"
    return(
        <div id="invitation" className={"flex justify-center items-center py-7"}>
            { i18n.language === "hu" && <img src={invitationHU} alt="" className={imageClasses}/> }
            { i18n.language === "en" && <img src={invitationEn} alt="" className={imageClasses}/> }
        </div>
    )
}
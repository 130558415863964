import { ImageCard } from "../../ImageCard";
import React from "react";
import { Link } from "react-scroll";
import ceremoniPlace from "../../../assets/images/ceromoni-place.jpeg";
import coctailParty from "../../../assets/images/coctail-party.jpeg";
import brunch from "../../../assets/images/brunch2.jpeg";
import { useTranslation } from "react-i18next";

export const LocationChooser = ({ id, filter }) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                id={ id }
                className={ `hidden md:grid grid-cols-1 sm:grid-cols-1 ${filter ? "md:grid-cols-3" : "md:grid-cols-2" } gap-12 p-4  }` }
            >
                <Link
                    to={ 'location-1' }
                    smooth={ true }
                    duration={ 500 }
                    offset={-70}
                    className="h-full cursor-pointer"
                >
                    <ImageCard
                        title={ 'Monkey Bistro' }
                        description={ "Budapest, Ybl Miklós tér 4, 1013 Hungary" }
                        image={ coctailParty }
                        date={t('location-1-date')}
                        day={t('location-1-title')}
                    />
                </Link>
                <Link
                    to={ 'location-2' }
                    smooth={ true }
                    duration={ 500 }
                    offset={-70}
                    className="h-full cursor-pointer"
                >
                    <ImageCard
                        title={ 'Villa Fontaine' }
                        description={ "Budapest, Lóránt út 15, 1125 Hungary" }
                        image={ ceremoniPlace }
                        date={t('location-2-date')}
                        day={t('location-2-title')}
                    />
                </Link>
                {filter && <Link
                    to={ 'location-3' }
                    smooth={ true }
                    duration={ 500 }
                    offset={-70}
                    className="h-full cursor-pointer"
                >
                    <ImageCard
                        title={ 'Citadella Guesthouse' }
                        description={ "Budapest, Rezeda u. 9, 1118 Hungary" }
                        image={ brunch }
                        date={t('location-3-date')}
                        day={t('location-3-title')}
                    />
                </Link>}

            </div>
        </>
    );
}
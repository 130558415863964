import { Card } from "../Card";
import React from "react";
import { useTranslation } from "react-i18next";
import question from "../../assets/images/question.jpeg"

export const Questions = () => {
    const { i18n } = useTranslation();

    return (
        <Card id="questions" className={ "min-h-[60vh] my-4 flex " }>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">

                <div className="order-2 md:order-1 flex justify-center items-center">
                    <div
                        className={ " flex justify-center items-center bg-white shadow-lg overflow-hidden rounded-lg" }>
                        { i18n.language === "hu" && <iframe
                            title="Kerdesek"
                            src="https://docs.google.com/forms/d/e/1FAIpQLSc-XNGWYAJ5cf5v-HNXhBnGmoltmVR-3Q1JwSWY18tQOJRl3A/viewform?embedded=true"
                            width="640"
                            height="700"
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0">Loading…</iframe> }

                        { i18n.language === "en" && <iframe
                            title="Question"
                            src="https://docs.google.com/forms/d/e/1FAIpQLScaDXoXUT_YpnNecArFZbLF53SCqaZ307omPTbNiZKc1JT0NA/viewform?embedded=true"
                            width="640"
                            height="700"
                            frameBorder="0"
                            marginHeight="0"
                            marginWidth="0">Loading…</iframe> }
                    </div>
                </div>

                <div className="order-1 md:order-2 flex justify-center items-center relative">
                    <img src={question} alt="question" className={"object-cover w-full rounded-lg "} />
                    <div className={ "absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center" }>
                        <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target={ "_blank" }
                           className={ "h-[100px] w-[100px]" } rel="noreferrer">
                            &nbsp;
                        </a>
                    </div>
                </div>
            </div>


        </Card>
    )
}
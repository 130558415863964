import React from "react";
import { Card } from "../Card";
import openImage from "../../assets/images/open-image.jpeg";
import openMobileText from "../../assets/images/open-mobile-text.jpeg";
import openMobileImage from "../../assets/images/open-mobile-image.jpeg";

export const InviteCard = ({ invite }) => {
    return (
        <Card id="invite-card" className={ "!bg-transparent shadow-none" }>
            <div className={ "flex flex-col items-center justify-center w-100" }>
                <img className="hidden md:block object-cover h-[89vh] xl:h-auto" src={openImage} alt="Card"/>
                {/* Mobile Image */}
                <img src={openMobileText} alt="Mobile" className="block md:hidden w-full" />
                <img src={openMobileImage} alt="Mobile" className="block md:hidden w-full" />
            </div>
        </Card>
    )
}

import React, { useEffect } from 'react';
import { Home } from './pages/Home';
import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navbar } from './components/Navbar';


const LangHandler = ({ children }) => {
    const { lang } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (i18n.hasResourceBundle(lang, 'translation')) {
            i18n.changeLanguage(lang)
        }
    }, [lang, i18n])
    return children
}


const App = () => {
    const { i18n } = useTranslation();

    return (
        <Router>
            <div>
                <Navbar/>
                <Routes>
                    <Route path="/" element={ <Navigate to={ `/${ i18n.language }` }/> }/>
                    <Route path="/:lang" element={ <LangHandler><Home/></LangHandler> }/>
                    <Route path="*" element={ <Navigate to={ `/${ i18n.language }` } replace/> }/>
                </Routes>
            </div>
        </Router>
    );
};

export default App;